<template>
  <el-scrollbar class="elv-landing-apply-free-container">
    <header>
      <img src="@/assets/img/left-meun-whole-logo.png" alt="logo" />
    </header>
    <section>
      <template v-if="!isSubmission">
        <div class="elv-landing-apply-free-info">
          <p class="elv-landing-apply-free-info__title">{{ t('title.applyFreeAccess') }}</p>
          <p class="elv-landing-apply-free-info__info">{{ t('message.applyFreeProjectTipInfo') }}</p>
        </div>
        <ProjectSettingForm
          planType="FREE"
          :isPublicFree="true"
          :showFormList="showFormList"
          @onCreateProjectSuccess="onCreateProjectSuccess"
        />
      </template>

      <div v-else class="elv-landing-apply-free-submission">
        <SvgIcon name="checkmark-filled" width="96" height="96" fill="#83AE00" />
        <b>{{ t('message.submissionSuccessful') }}</b>
        <p>{{ t('message.submissionSuccessfulInfo') }}</p>
      </div>
    </section>
  </el-scrollbar>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
// import { ElMessage } from 'element-plus'
import { useNavigatorLanguage } from '@vueuse/core'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import ProjectSettingForm from '@/components/Project/ProjectSettingForm.vue'

const route = useRoute()
const { t, locale } = useI18n()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)
const { language } = useNavigatorLanguage()

const isSubmission = ref(false)

const showFormList = [
  'name',
  'firstName',
  'lastName',
  'companyName',
  'employees',
  'website',
  'jobTitle',
  'email',
  'region'
]

/**
 * 创建免费项目完成后操作
 */
const onCreateProjectSuccess = () => {
  isSubmission.value = true
}

const setRealVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

watch(
  () => route,
  () => {
    if (route?.name === 'basic-free-project' && !user.value?.email) {
      isSubmission.value = false
      setRealVh()
      window.addEventListener('resize', setRealVh)
      if (language.value === 'zh-CN') {
        localStorage.setItem('language', 'zh')
        locale.value = 'zh'
      } else {
        localStorage.setItem('language', 'en')
        locale.value = 'en'
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  window.removeEventListener('resize', setRealVh)
})
</script>

<style lang="scss" scoped>
:root {
  --vh: 100%;
}

.elv-landing-apply-free-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: 768px) {
    .el-scrollbar__view {
      header {
        height: 72px;
        margin-bottom: 16px;

        img {
          width: 99.999px;
          height: 29.076px;
        }
      }
    }

    .elv-landing-apply-free-info {
      width: calc(100vw - 44px);

      .elv-landing-apply-free-info__title {
        font-size: 18px;
      }

      .elv-landing-apply-free-info__info {
        font-size: 12px;
      }
    }

    .elv-landing-apply-free-submission {
      b {
        margin-top: 16px;
      }

      p {
        width: calc(100vw - 44px);
      }
    }

    :deep(.elv-setting-project-form) {
      margin-top: 25px;

      .el-input,
      .el-select {
        width: calc(100vw - 44px) !important;
      }

      .el-input__wrapper {
        height: 100%;
      }

      .el-form,
      .el-form-item,
      .el-form-item__label {
        width: fit-content;
      }

      .el-select__wrapper {
        width: 100%;
      }

      .el-form-item__content {
        flex-direction: column;
      }

      .elv-setting-project-form-term-service {
        height: fit-content;
        width: calc(100vw - 44px) !important;
      }
    }
  }

  :deep(.el-scrollbar__wrap) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .el-scrollbar__view {
      height: 100%;
      width: 100%;
    }
  }

  header {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edf0f3;
    margin-bottom: 42px;

    img {
      width: 140px;
      height: 42px;
      display: block;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  :deep(.elv-setting-project-form) {
    margin-top: 42px;

    .elv-apply-trial-submit {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.elv-landing-apply-free-info {
  display: flex;
  flex-direction: column;
  width: 572px;

  .elv-landing-apply-free-info__title {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    color: #1e2024;
    text-align: center;
  }

  .elv-landing-apply-free-info__info {
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
    max-width: 572px;
  }
}

.elv-landing-apply-free-submission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Plus Jakarta Sans';
  margin-top: 78px;

  b {
    color: #1e2024;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;
  }

  p {
    max-width: 572px;
    color: #636b75;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
  }
}

.elv-apply-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
  text-align: center;
  word-break: break-word;
}
</style>
